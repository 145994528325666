import React from "react";
import { BsDiscord, BsYoutube, BsInstagram, BsTwitter } from "react-icons/bs";

import { ReactComponent as MwfLogo } from "../../assets/logoMwf.svg";
import { Link } from "react-router-dom";

const Footer = () => {
  const currentYear = new Date().getFullYear();
  return (
    <div className="bg-backgroundFooterMobile md:bg-backgroundFooter  bg-cover md:grid md:grid-cols-10 text-white  ">
      <div className="col-span-6 space-y-4 ">
        <div className="  md:w-[50%] mx-auto">
          <div className="w-fit pt-16  md:pt-24 mx-auto md:mx-0">
            <MwfLogo />
          </div>
          <p className="pt-10 md:text-left  w-[80%] md:w-[80%] md:pt-4 mx-auto md:mx-0 text-center ">
            Metaverse Weapon Factory creates unique NFT weapons with advanced
            technology. Follow us for new releases and industry news. Join the
            metaverse revolution now
          </p>
          <ul className="mx-auto md:mx-0 w-fit flex space-x-4 pt-6 text-[#00DBCE]">
            <li>
              <a
                className="text-[#00DBCE]"
                href="https://twitter.com/mwf_io"
                rel="noopener noreferrer"
                target="_blank"
              >
                <BsTwitter size="2em" />
              </a>
            </li>
            <li>
              <a
                className="text-[#00DBCE]"
                href="https://www.youtube.com/@metaverseweaponsfactory6121"
                rel="noopener noreferrer"
                target="_blank"
              >
                <BsYoutube size="2em" />
              </a>
            </li>
            <li>
              <a
                className="text-[#00DBCE]"
                href="https://www.instagram.com/mwf_io/"
                rel="noopener noreferrer"
                target="_blank"
              >
                <BsInstagram size="2em" />
              </a>
            </li>
            <li>
              <a
                className="text-[#00DBCE]"
                href="https://discord.com/invite/FdrfJwCyUu"
                rel="noopener noreferrer"
                target="_blank"
              >
                <BsDiscord size="2em" />
              </a>
            </li>
          </ul>
          <p className="hidden md:block md:py-20 text-[8px] text-left leading-3 uppercase font-semibold -tracking-tighter">
            Copyright © {currentYear} Metaverse weapon factory, Inc. All right
            reserved
          </p>
        </div>
      </div>
      <div className=" col-span-1 md:pt-24 w-fit mx-auto pt-12 md:text-left text-center">
        <ul className="space-y-8 pb-8">
          <li className="text-[20px]">Site Map</li>
          <li>
            <a href="#home">Home</a>
          </li>
          <li>
            <a href="#about">About</a>
          </li>
          <li>Upcoming Mints</li>
          <li>
            <a href="#game">Game</a>
          </li>
          <li>
            <a href="#roadmap"> Road map</a>
          </li>
        </ul>
      </div>
      <div className=" col-span-2 md:pt-24 w-fit mx-auto md:text-left text-center">
        <ul className="space-y-8">
          <li className="text-[20px]">Company</li>
        </ul>
        <div className="mt-8">
          <Link to="cgu">
            <p className="pb-8">Terms & Conditions</p>
          </Link>
          <Link to="privacy">
            <p>Privacy Policy</p>
          </Link>
        </div>
      </div>
      <p className="md:hidden md:py-20 text-[7px] w-fit mx-auto text-center pt-24 pb-16 leading-3 uppercase font-semibold -tracking-tighter">
        Copyright © {currentYear} Metaverse weapon factory, Inc. All right
        reserved
      </p>
    </div>
  );
};

export default Footer;
