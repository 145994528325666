// import logo from './logo.svg';
import "./App.css";

import React from "react";
import Home from "./routes/home/Home";
import { Routes, Route } from "react-router-dom";
import Navigation from "./sections/navigation/Navigation";
import Cgu from "./routes/cgu/Cgu";
import Privacy from "./routes/privacy/Privacy";
import ScrollToTop from "./configComponents/ScrollToTop";

const App = () => {
  return (
    <ScrollToTop>
      <Routes>
        <Route path="/" element={<Navigation />}>
          <Route index element={<Home />} />
        </Route>
        <Route path="/cgu" element={<Cgu />} />
        <Route path="/privacy" element={<Privacy />} />
      </Routes>
    </ScrollToTop>
  );
};

export default App;
