import React from "react";
// import { Outlet, Link } from "react-router-dom";
import About from "../../sections/about/About";
import Footer from "../../sections/footer/Footer";
import Homepage from "../../sections/homepage/Homepage";
import Info from "../../sections/infoDownload/Info";
import Media from "../../sections/media/Media";
// import Mint from "../../sections/mints/Mint";
import Roadmap from "../../sections/roadmap/Roadmap";

import "./home.styles.css";
const Home = () => {
  return (
    <>
      <Homepage />
      <About />
      <Media />
      <Roadmap />
      <Footer />
    </>
  );
};
// <Info />

export default Home;
