import React, { Fragment } from "react";
import { IoMdArrowBack } from "react-icons/io";
import { useNavigate } from "react-router-dom";

const GoBack = () => {
  const navigate = useNavigate();
  const goBack = () => {
    navigate("/");
  };
  return (
    <Fragment>
      <IoMdArrowBack
        onClick={goBack}
        size="2em"
        className="md:hidden block float-left w-fit mt-3 relative"
      />
    </Fragment>
  );
};

export default GoBack;
