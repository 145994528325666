import React, { useEffect, useState } from "react";
import "./navigation.styles.css";
import { Outlet, Link } from "react-router-dom";
import { ReactComponent as MwfLogo } from "../../assets/logoMwf.svg";
import { ReactComponent as MwfLogoMobile } from "../../assets/mobile-logo.svg";
import { BsDiscord } from "react-icons/bs";
import { AiOutlineClose, AiOutlineMenu } from "react-icons/ai";
import Button from "../../reusableComponents/button/Button";

const Navigation = () => {
  const [nav, setNav] = useState(false);
  const handleNav = () => setNav(!nav);

  useEffect(() => {
    const handleScroll = (e) => {
      if (nav) {
        e.preventDefault();
        e.stopPropagation();
      }
    };

    if (nav) {
      document.body.style.overflow = "hidden";
      window.addEventListener("scroll", handleScroll, { passive: false });
    } else {
      document.body.style.overflow = "auto";
      window.removeEventListener("scroll", handleScroll);
    }

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [nav]);

  return (
    <>
      <div className="   md:bg-cover  bg-[#192b2d]  md:bg-backgroundMwfNav flex justify-between  max-w-full max-md:py-2 md:h-20 mx-auto  border-b border-b-gray-400/50 items-center px-4  text-white">
        <Link className=" pl-16 w-[50%]" to="/">
          <MwfLogo className="hidden md:block" />
          <MwfLogoMobile className="block md:hidden" />
        </Link>

        <ul className="hidden md:flex items-center">
          <li>
            <a
              className="hover:text-[#CEEBDF] transition ease-in-out   duration-300 p-4 nav_link"
              href="#about"
            >
              Discover
            </a>
          </li>
          <li>
            <Link className="hover:text-[#CEEBDF] transition ease-in-out   duration-300 p-6 nav_link">
              Upcoming
            </Link>
          </li>
          <li>
            <a
              href="#game"
              className="hover:text-[#CEEBDF] transition ease-in-out   duration-300 p-6 nav_a"
            >
              Game
            </a>
          </li>
          <li>
            <a
              href="#roadmap"
              className="hover:text-[#CEEBDF] transition ease-in-out   duration-300 p-6 nav_link"
            >
              Roadmap
            </a>
          </li>
          <li>
            <a
              className="px-0"
              href="https://discord.com/invite/FdrfJwCyUu"
              rel="noopener noreferrer"
              target="_blank"
            >
              <BsDiscord
                className="hover:text-[#CEEBDF] w-[100px] transition ease-in-out  hover:scale-110 duration-300"
                size={30}
              />
            </a>
          </li>
        </ul>
        <div onClick={handleNav} className="   md:hidden">
          {nav ? null : <AiOutlineMenu size={35} />}
        </div>
        <div className="hidden lg:block justify-center pr-10">
          <a href="#whitelist">
            <Button label="Whitelist" />
          </a>
        </div>
        {nav && (
          <div
            className={
              nav
                ? " transition ease-in-out fixed left-0 h-full top-0 right-0 w-[100%] z-[998] overflow-x-auto bg-backgroundMwf duration-500"
                : "fixed left-[-100%]"
            }
            style={{
              overflow: nav ? "hidden" : "auto",
              height: nav ? "100vh" : "auto",
            }}
          >
            <div className="max-md:py-2 flex items-center">
              <div className="  mr-[5.5rem] w-fit mx-auto">
                <MwfLogoMobile className="  my-2 " />
              </div>
              <AiOutlineClose className="mx-4" onClick={handleNav} size={35} />
            </div>

            <ul className=" w-fit mx-auto text-center uppercase p-4 ">
              <li className="p-6 hover:text-[#CEEBDF] transition ease-in-out   duration-300 ">
                <a onClick={handleNav} href="#about">
                  Discover
                </a>
              </li>
              <li className="p-6 hover:text-[#CEEBDF] transition ease-in-out   duration-300 ">
                Upcoming Mint
              </li>
              <li className="p-6 hover:text-[#CEEBDF] transition ease-in-out   duration-300 ">
                <a onClick={handleNav} href="#game">
                  Game
                </a>
              </li>
              <li className="p-6 hover:text-[#CEEBDF] transition ease-in-out   duration-300 ">
                <a onClick={handleNav} href="#roadmap">
                  Roadmap
                </a>
              </li>{" "}
              <hr className="w-[50%] mx-auto p-4 " />
              <li>
                <a
                  className="max-w-fit mx-auto"
                  href="https://discord.com/invite/FdrfJwCyUu"
                >
                  <BsDiscord
                    className="mx-auto hover:scale-110 hover:text-[#CEEBDF] transition ease-in-out duration-300"
                    size={40}
                  />
                </a>
              </li>
            </ul>
            <div className=" max-w-fit mx-auto">
              <a onClick={handleNav} href="#whitelist">
                <Button label="Whitelist" />
              </a>
            </div>
          </div>
        )}
      </div>
      <Outlet />
    </>
  );
};
export default Navigation;
