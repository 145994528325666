import React, { Fragment } from "react";
import "./roadmap.css";

import {
  AiFillCaretLeft,
  AiFillCaretRight,
  // AiFillCaretDown,
} from "react-icons/ai";
import { ReactComponent as Oculus } from "../../assets/oculus.svg";
import { ReactComponent as Unity } from "../../assets/unity.svg";
import { ReactComponent as Android } from "../../assets/android.svg";
import { ReactComponent as Opensea } from "../../assets/opensea.svg";

import { ReactComponent as Windows } from "../../assets/windows.svg";

import Button from "../../reusableComponents/button/Button";
import CardComponent from "../../reusableComponents/roadCard/CardComponent";

export const dataRoad = [
  {
    month: "Q1 2022",
    title: "Project Kickoff & Initial Development",
    description:
      "Assemble a talented team of developers,artists. \nEstablish a unique vision for the ultimate metaverse. \nDevelop a 3D weapon generator, ERC-721 smart contracts and Opensea-compatible NFTs. \nCreate and launch the VR shooting range game with NFT integration.",
  },

  {
    month: "Q2 2022",
    title: "Weapon Design & Innovation",
    description:
      "  Utilize advanced 3D modeling and rendering for stunning visuals. \nCreate customizable weapon skins, effects, and upgradable tiers. \nOffer an array of weapon categories, including handguns, knives, rifles, and more.",
  },
  {
    month: "Q3 2022",
    title: "First Mint & Augmented Reality",
    description:
      "Launch the first mint of AK47 NFT weapons on April 9th, offering a variety of weapon categories. \nIntroduce immersive augmented reality previews and crafting systems.",
  },
  {
    month: "Q4 2022",
    title: "Building a Thriving Community & SDK Development",
    description:
      "Engage users through social media campaigns and design contests. \nOffer exclusive, limited-edition weapons and collaborations within each category. \nDevelop an SDK for seamless integration with various platforms.",
  },
  {
    month: "Q1 2023",
    title: " Expanding, Collaborations & Mod Support",
    description:
      "Partner with leading metaverse platforms and game titles for native integration. \nCollaborate with renowned artists and designers for unique weapon designs. \nAdd mod support for popular games, enabling weapon integration and customization.",
  },
  {
    month: "Q2 2023",
    title: "Customization & Immersive Experiences",
    description:
      "Provide weapon personalization options and customizable sound effects. \nOffer virtual reality weapon testing and training simulations.",
  },
  {
    month: "Q3 2023",
    title: "Ecosystem Growth & User Engagement",
    description:
      "Release weapon metadata directory for easy access and integration. \nProvide assets for Unity and Unreal Engine to support game developers. \nLaunch weapon-specific challenges, leaderboards, and battle simulation modes.",
  },
  {
    month: "Q4 2023",
    title: " Exciting Events & Gamification",
    description:
      "Organize in-game and real-life weapon-inspired events and metaverse-wide exhibitions. \nDevelop weapon-related quests, user reputation systems, and weapon-themed tournaments.",
  },
  {
    month: "Q1 2024",
    title: "Continuous Evolution & Growth",
    description:
      "Invest in cutting-edge technology for adaptive weapon performance. \nExplore NFT weapon insurance options and subscription-based weapon club memberships.",
  },
  {
    month: "Q2 2024 and beyond",
    title: "Shaping the Future of Metaverse Weaponry",
    description:
      "Establish Metaverse Weapon Factory as the market leader in the NFT weapon industry. \nFoster a passionate and loyal community to drive innovation and growth.",
  },
];

//useEffect statement ?

const CardContent = (id) => {
  return (
    <Fragment>
      <h3 className="text-center pb-2">{dataRoad[id.id].month}</h3>
      <h3 className="font-semibold text-lg mb-1 text-center md:text-left">
        {dataRoad[id.id].title}
      </h3>
      <p className="text-[#B4C5CB] leading-tight text-center md:text-left">
        {dataRoad[id.id].description.substring(0, 157)}
        {dataRoad[id.id].description.length > 157 ? (
          <span>&nbsp;...</span>
        ) : (
          <span>.</span>
        )}
      </p>
    </Fragment>
  );
};

const ArrowDiv = (direction) => {
  let arrowBody = "-----------";
  let arrowMobileBody = "| | | | |";

  switch (direction) {
    case "left":
      return (
        <div className="bg-white  relative md:right-[5.5rem] 2xl:right-[8.5rem] 3xl:right-[9.5rem] border-dashed border-[#1D1D1D] border h-[1px]  md:w-16 2xl:w-28 3xl:w-32 items-center  ml-4 mt-2">
          <AiFillCaretLeft
            className="my-auto float-left relative bottom-[7px] right-3"
            size="14"
          />
        </div>
      );

    case "right":
      return (
        <div className="bg-white relative left-[0.7rem] border-dashed border-[#1D1D1D] border h-[1px]  md:w-16 2xl:w-28 3xl:w-32 items-center  ml-4 mt-2">
          <AiFillCaretRight
            className="my-auto float-right relative bottom-[7px] left-3"
            size="14"
          />
        </div>

        // <AiFillCaretRight className="my-auto" size="12" />
      );

    case "mobile":
      return (
        <div className=" text-[7px] relative left-1/2 top-11 pointer-events-none bg-transparent h-4 w-1 items-center mt-[-1vh]">
          {arrowMobileBody}
          {/*<AiFillCaretDown className="ml-[-4px]" size="12" />*/}
        </div>
      );

    default:
      return (
        <Fragment>
          <div className="bg-white  relative left-[0.7rem] border-dashed border-[#1D1D1D] border h-[1px] w-24 items-center  ml-4 mt-2"></div>
        </Fragment>
      );
  }
};

const NodeBody = (directionCard) =>
  directionCard ? (
    <div className="h-fit w-6 flex items-center justify-center">
      <div className=" hidden md:block md:mt-[-18vh] 3xl:mt-[-12vh] h-28 w-1 bg-[#47FFF4] pointer-events-none"></div>
    </div>
  ) : (
    <div className="h-full w-6 flex items-center justify-center ">
      <div className="h-full w-1 bg-[#47FFF4] pointer-events-none"></div>
    </div>
  );

const Card = (directionCard, i) => {
  switch (directionCard) {
    case "first":
      return (
        <Fragment>
          {" "}
          <div className="flex-row-reverse">
            <div className=" pl-0 mt-[4vh] card-container-left-v2 borderMask">
              <div className="card-left-v2">{<CardContent id={i} />}</div>
            </div>
            <div className="  top-32 h-[50%] col-start-5 col-end-6 md:mx-auto relative mr-10">
              {NodeBody()}
              <div className="hidden md:block road-node top-0">
                {ArrowDiv("left")}
              </div>
            </div>
          </div>
          <div className="md:hidden w-fit mx-auto   text-white">
            {ArrowDiv("mobile")}
          </div>
        </Fragment>
      );
    case "left":
      return (
        <Fragment>
          <div className=" flex-row-reverse">
            <div className=" pl-0 mt-[2vh] card-container-left-v2 borderMask ">
              <div className="card-left-v2">{<CardContent id={i} />}</div>
            </div>
            <div className="hidden md:block  col-start-5 col-end-6 md:mx-auto relative mr-10">
              {NodeBody()}
              <div className="road-node top-30 ">{ArrowDiv("left")}</div>
            </div>
          </div>
          <div className="md:hidden w-fit mx-auto   text-white">
            {ArrowDiv("mobile")}
          </div>
        </Fragment>
      );

    case "right":
      return (
        <Fragment>
          <div className="">
            <div className="hidden md:block col-start-5 col-end-6 mr-10 md:mx-auto relative">
              {NodeBody()}
              <div className="road-node top-4">{ArrowDiv("right")}</div>
            </div>
            <div className="pr-0 card-container-right-v2 borderMask-right">
              <div className=" card-right-v2">{<CardContent id={i} />}</div>
            </div>
          </div>
          <div className="md:hidden w-fit mx-auto   text-white">
            {ArrowDiv("mobile")}
          </div>
        </Fragment>
      );
    case "last":
      return (
        <Fragment>
          <div className="">
            <div className=" hidden md:block col-start-5 col-end-6 mr-10 h-0 mt-24 md:mx-auto relative">
              {NodeBody("last")}
              <div className="road-node">{ArrowDiv("right")}</div>
            </div>
            <div className="pr-0 card-container-right-v2 borderMask-right mt-[3vh] pb-0 ">
              <div className=" card-right-v2">{<CardContent id={i} />}</div>
            </div>
          </div>
        </Fragment>
      );
    default:
      break;
  }
};

const FooterRoad = () => {
  const roadDescription = "The largest and unique Super rare NFT marketplace";
  const roadDescriptionExtend = "For crypto-collectibles";
  const DescriptionRoad = () => (
    <Fragment>
      <p className=" md:text-[20px]">{roadDescription}</p>
      <p className=" pb-2  md:text-[20px]">{roadDescriptionExtend}</p>
    </Fragment>
  );

  const DescriptionRoadMobile = () => (
    <Fragment>
      <p className="pb-2 text-[16px]">
        {roadDescription + " " + roadDescriptionExtend}
      </p>
    </Fragment>
  );

  return (
    <div className=" bg-[#1D1D1D] h-full w-full max-w-[1240px]  mx-auto text-white text-center">
      <h1 className="text-[#00dbce] pt-8 drop-shadow-3xl font-bold text-[26px] md:text-[36px] pb-4">
        Available on
      </h1>
      {/*
      <div className="hidden md:block">{DescriptionRoad()}</div>
        <div className="md:hidden w-[80%] mx-auto pb-14">
          {DescriptionRoadMobile()}
        </div>
    */}
      <div className="md:flex grid grid-cols-2  justify-center pt-4 md:space-x-8 pb-10">
        <Unity className="p-3 pr-10 md:p-0 md:pr-10" />
        <img
          src="assets/opensea.png"
          alt="opensea logo"
          className="p-3 pr-10 md:p-4 md:pr-10"
        />
        <Oculus className="pr-10 ml-5 md:pr-0 mt-7 md:mt-5" />
        <img
          src="assets/winImg.png"
          alt="windows logo"
          className="p-3 mt-3 md:mt-0 md:p-0 mx-auto"
        />
        <Android className="pr-10 md:pr-0 ml-5 mt-4 md:mt-3" />
        <div className="md:relative md:top-3 block mx-auto">
          <img
            src="assets/ios.png"
            alt="ios logo"
            className="p-3 md:p-0 md:mb-4 "
          />
        </div>
      </div>
    </div>
  );
};
// <div>
// <p className="  md:text-[20px]">{roadDescription}</p>
// <p className="pb-2  md:text-[20px]">{roadDescriptionExtend}</p>
// </div>
const Roadmap = () => {
  // useEffect(() => {
  //   dataRoad.map((element) => console.log(element));
  // }, []);

  // const DescriptionRoadMobile = () => (
  //   <Fragment>
  //     <p className="pb-6">{roadDescription + " " + roadDescriptionExtend}</p>
  //   </Fragment>
  // );

  // const DescriptionRoad = () => (
  //   <Fragment>
  //     <p className="pb-4 md:w-[100%] 3xl:w-[100%]  md:text-[20px]">
  //       {roadDescription}
  //     </p>
  //     <p className="pb-8 md:w-[80%]  md:text-[20px]">{roadDescriptionExtend}</p>
  //   </Fragment>
  // );
  return (
    <Fragment>
      <div
        id="roadmap"
        className=" bg-backgroundRoadMapMobile  bg-cover md:bg-backgroundRoadMap md:bg-cover"
      >
        <div className="pb-0 mb-[-2vh] md:mb-0 py-6 bg-transparent md:bg-backgroundRoadMap text-white text-center mx-auto">
          <p className="text-[#00dbce] drop-shadow-3xl mb-24 font-bold text-[26px] md:text-[36px]">
            Road map
          </p>
          {/*
          <p className="tracking-wide mb-24 text-[15px] w-[80%] md:text-[20px] md:w-[34%] 3xl:w-[27%] mx-auto">
            The largest and unique Super rare NFT marketplace For
            crypto-collectibles
          </p>       
          */}
        </div>
        {/*<div className=" container justify-center flex-col mx-auto flex w-full h-full  p-4 text-blue-50">
          {dataRoad.map((el) => (
            <div className=" flex">
              <div className="road-node my-auto"></div>

              <div className="h-fit my-auto ">{ArrowDiv("right")}</div>
              <div className="">
                <div className=" pl-0  card-container-right-v2 borderMask-right">
                  <div className="card-right-v2">
                    {<CardComponent roadData={el} />}
                  </div>
                </div>
              </div>
            </div>
          ))}
          </div>*/}
        <div className=" container mx-auto w-full h-full  p-4 text-blue-50">
          <div className="relative wrap h-full">
            <div className="hidden md:block left-[50%] md:top-[5.6%] lg:top-[5%] 2xl:top-[4.8%] 3xl:top-[5%] border-2-2 absolute border-[#47FFF4] h-[88.2%] border"></div>

            {dataRoad.map((el, index) => (
              <Fragment key={el.title}>
                <div
                  className={
                    index % 2 !== 0
                      ? `mb-8 flex justify-between items-center w-full `
                      : `mb-8 flex justify-between  flex-row-reverse items-center w-full `
                  }
                >
                  <div className="hidden md:block order-1 w-5/12 "></div>

                  <div
                    className={
                      index % 2 === 0
                        ? `hidden md:block road-node `
                        : `hidden md:block road-node relative bottom-20`
                    }
                  >
                    <div className="">
                      {ArrowDiv(index % 2 === 0 ? "left" : "right")}
                    </div>{" "}
                  </div>
                  {/* 
                  <div className="">
                      {ArrowDiv(index % 2 === 0 ? "left" : "right")}
                    </div>{" "}
              */}
                  <div className="order-1 md:w-5/12 px-0 md:px-6 py-4">
                    <div
                      className={
                        index % 2 === 0
                          ? `hidden md:block pl-0  card-container-left-v2 borderMask `
                          : `hidden md:block pl-0  card-container-right-v2 borderMask-right`
                      }
                    >
                      <div
                        className={
                          index % 2 === 0 ? `card-left-v2  ` : `card-right-v2 `
                        }
                      >
                        <CardComponent roadData={el} />
                      </div>
                    </div>

                    <div className="md:hidden card-container-right-v2 borderMask-mobile">
                      <div className="card-default ">
                        <CardComponent roadData={el} />
                        {index !== dataRoad.length - 1
                          ? ArrowDiv("mobile")
                          : ""}
                      </div>
                    </div>
                  </div>
                </div>
              </Fragment>
            ))}
          </div>
        </div>
        <div className="hidden pb-24 pt-6 max-w-fit mx-auto text-white">
          <Button label="explore more" />
        </div>
      </div>
      {FooterRoad()}
    </Fragment>
  );
};

export default Roadmap;
