import { Fragment, useState } from "react";
import clsx from "clsx";
import { GiM3GreaseGun } from "react-icons/gi";
import { GiPistolGun } from "react-icons/gi";

const Accordeon = ({
  title,
  content,
  contentPart2,
  customClassTitle,
  customClassContent,
  parentClass,
  open,
}) => {
  return (
    <Fragment>
      <div className={parentClass}>
        <div
          data-state={!open}
          className={clsx({
            "  data-[state=false]:opacity-100 transition  text-white md:text-center text-left ease-in-out duration-[2000ms]":
              open,
            "  opacity-30  ": !open,
          })}
        >
          <h1 className={customClassTitle}>{title}</h1>
          <p
            data-state={open}
            className={`${customClassContent} data-[state=false]:hidden`}
          >
             {/*
             <GiM3GreaseGun className="float-left md:ml-8" size="1.3em" /> &nbsp;
  */}
            {content} <br />
               {/*
  
               <GiPistolGun className="float-left md:ml-8" size="1.3em" /> &nbsp;
  */}
            {contentPart2}
          </p>
          <h1 className={customClassTitle}>
            3. Exclusive Collection of Inimitable Digital Weapons
          </h1>
          <p className={customClassContent}>
               {/*
  
               <GiM3GreaseGun className="float-left md:ml-8" size="1.3em" /> &nbsp;
  */}
            By choosing mwf.io, gamers have access to an exclusive collection of
            digital weapons that are unique, personalized, and inimitable. The
            platform provides gamers with the opportunity to showcase their
            status, personalize their gaming experience, and even invest in a
            new asset class. <br />{" "}
               {/*
  
               <GiPistolGun className="float-left md:ml-8" size="1.3em" />{" "}
  */}
            &nbsp;Thank you for considering mwf.io for your gaming needs - their
            commitment to quality, security, and innovation ensures a
            one-of-a-kind experience for all gamers.
          </p>
        </div>
      </div>
    </Fragment>
  );
};

export default Accordeon;
