import React, { useEffect, useState } from "react";
import "./about.styles.css";
import { RiAccountCircleLine } from "react-icons/ri";
import { GiM3GreaseGun } from "react-icons/gi";
import { GiPistolGun } from "react-icons/gi";

import { ReactComponent as Info } from "../../assets/vector.svg";
import { ReactComponent as Cross } from "../../assets/cross.svg";

import Button from "../../reusableComponents/button/Button";
import Accordeon from "../../reusableComponents/accordeon/Accordeon";
import { Helmet } from "react-helmet";

const About = () => {
  const [open, setOpen] = useState(false);
  const [windowSize, setWindowSize] = useState([
    window.innerWidth,
    window.innerHeight,
  ]);
  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize([window.innerWidth, window.innerHeight]);
    };

    window.addEventListener("resize", handleWindowResize);
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, [windowSize]);

  //   let Variants = 'xl' | 'xs'

  const handleClick = () => {
    setOpen(!open);
  };
  const renderTitle = () => {
    return (
      <p className="text-[#00dbce] drop-shadow-3xl font-bold text-[36px]">
        About MWF
      </p>
    );
  };

  const accordeonDescription = () => {
    return (
      <Accordeon
        parentClass={
          "mb-3 mx-4 md:mx-0 mt-2 leading-[35px] tracking-[0.03em] text-left text-lg w-full mx-auto md:mt-2 mt-10   "
        }
        customClassTitle={
          "pl-3 mb-4 md:mb-0 md:text-[26px] text-[22px] font-semibold  pb-2 "
        }
        customClassContent={"mb-2 md:w-[80%] md:text-justify md:mx-auto"}
        title={"2. Blockchain Security Ensures Authenticity and Flexibility"}
        content={
          " The weapons are securely stored on the blockchain, guaranteeing their authenticity and enabling gamers to buy, sell, and trade them on the open market. The team at mwf.io is dedicated to providing an unparallel gaming experience to their customers."
        }
        contentPart2={
          "They invest significant effort in developing weapons that are both visually stunning and functionally powerful, with a diverse selection of options that are fully licensed and legal."
        }
        open={open}
      />
    );
  };

  const renderDescription = () => {
    return (
      <div
        data-state={!open}
        className="  md:text-center data-[state=false]:max-h-full md:w-[90%] 3xl:w-full mb-3 mx-4 md:mx-auto mt-4 md:mt-4 md:mb-0 leading-[35px] tracking-[0.03em] text-left text-lg max-h-72 md:max-h-[13rem] overscroll-none overflow-hidden "
        data-te-collapse-item
        id="collapseWithScrollbar"
      >
        <h1 className="pl-3 mb-4 md:mb-0 md:text-[26px] text-[22px] font-semibold pb-2">
          1. Unique and Rare Digital Weapons for the Metaverse
        </h1>
        <p className="md:w-[80%] md:text-justify md:mx-auto">
          {/*
    <GiM3GreaseGun className="float-left md:ml-8" size="1.3em" /> &nbsp;
  */}
          Mwf.io is a sophisticated platform where gamers can collect unique and
          rare digital weapons for the metaverse. Each weapon is created using
          state
          <span
            data-state={open}
            className="data-[state=false]:max-md:opacity-60"
          >
            -of-the-art&nbsp;3D technology, resulting in a vast range of rarity
            and exclusivity. <br />
          </span>
          {/*
  
      <GiPistolGun className="float-left md:ml-8" size="1.3em" /> &nbsp;
    */}
          <span
            data-state={open}
            className="data-[state=false]:max-md:opacity-40 data-[state=false]:2xl:opacity-40"
          >
            The platform offers a wide selection of licensed weapons, from
            classic
          </span>
          <span
            data-state={open}
            className="data-[state=false]:max-md:opacity-10 data-[state=false]:2xl:opacity-40"
          >
            &nbsp; swords and guns to rare and exotic weaponry,{" "}
            <span
              data-state={open}
              className="data-[state=false]:max-md:opacity-40 data-[state=false]:2xl:opacity-80"
            >
              {" "}
              catering to the diverse tastes and preferences of the gaming
              community.
            </span>
          </span>
        </p>
        <div data-state={!open} className="data-[state=false]:hidden">
          <h1 className="mt-2 opacity-10 pl-3 mb-4 md:mb-0 md:text-[26px] text-[22px] font-semibold ">
            2. Blockchain Security Ensures Authenticity and Flexibility
          </h1>
          {/* <p className="opacity-10">
            The weapons are securely stored on the blockchain, guaranteeing
            their authenticity and enabling gamers to buy, sell, and
            <span className="opacity-20">
              &nbsp; trade them on the open market. The team at mwf.io is
              dedicated to providing an unparallel...
            </span>
          </p> */}
        </div>
        {accordeonDescription()}
      </div>
    );
  };

  const formEmail = () => {
    return (
      <form className="w-full mx-auto max-w-sm">
        <div className=" border border-[#33E9E9] rounded-lg bg-transparent w-full mx-auto text-black">
          <RiAccountCircleLine className="float-left  ml-1 mt-1 text-[#33E9E9]/90 text-[2em]" />
          <input
            className=" focus:outline-offset-0 focus:outline-[#00423E] placeholder-[#33E9E9] ml-4 w-2/3 bg-transparent border-none text-white"
            type="email"
            placeholder="Your Email"
          />
          <button className="float-right transition ease-in-out  hover:bg-[#47FFF4]/5  hover:text-[#8CFFF7] duration-300 border-none rounded-r-md  bg-[#33E9E9;] py-2 px-2 font-semibold">
            confirm
          </button>
        </div>
      </form>
    );
  };

  const infoMessage = () => {
    return (
      <div className=" uppercase p-4 flex mt-4 border border-[#33E9E9] rounded-lg  w-fit mx-auto  bg-[#33E9E9]/70 text-white">
        <Info className="ml-1 my-auto" />
        <p className="  font-Inter ml-2 font-bold  text-[#00423E] dark:text-gray-300">
          Info Message
        </p>
        <Cross className=" m-4 my-auto " />
      </div>
    );
  };

  const agreeMessage = () => {
    return (
      <div className=" mx-4 md:mx-0 text-[14px] leading-none text-left md:text-center font-Inter mt-4 p-auto font-normal text-[white]">
        <input
          className="text-[#33E9E9]  mx-2 float-left md:float-none w-[29px] h-[29px] border border-[#33E9E9] rounded-[5px] bg-transparent my-auto"
          type="checkbox"
        ></input>
        <label>
          I agree to the storage of my personal information and to receive
          Emails from MWF
        </label>
      </div>
    );
  };
  return (
    <>
      <div id="about" className="text-white bg-cover bg-backgroundAbout ">
        <div className=" max-w-[1240px] mt-[-96px] justify-center text-center mx-auto flex flex-col w-full h-fit py-4">
          {renderTitle()}
          {renderDescription()}

          <div
            onClick={handleClick}
            className="font-bold py-4 flex justify-center items-center"
          >
            <Button label={!open ? "read more" : "read less"} />
          </div>
          <p
            id="whitelist"
            className="md:text-2xl text-xl font-bold text-white drop-shadow-3xl  py-4"
          >
            Join the whitelist
          </p>
          {formEmail()}
          {/* {infoMessage()} */}
          {agreeMessage()}
        </div>
      </div>
    </>
  );
};

export default About;
