import React, { Fragment } from "react";

const CardComponent = ({ roadData }) => {
  // console.log(roadData);
  const { month, title, description } = roadData;

  let descriptionFormat = description.split("\n").map((line, index) => (
    <Fragment key={index}>
      <ol className="list-disc ml-2 py-1">
        <li>{line}</li>
      </ol>
    </Fragment>
  ));

  return (
    <Fragment>
      <h3 className="text-center pb-2 md:pb-0">{month}</h3>
      <h3 className="font-semibold text-center md:text-left text-lg mb-1">
        {" "}
        {title}
      </h3>
      <p className="text-[#B4C5CB] leading-tight text-left md:text-left">
        {descriptionFormat}
      </p>
    </Fragment>
  );
};

export default CardComponent;
