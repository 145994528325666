import { Helmet } from "react-helmet";
import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import SEO from "./utils/SEO";
import logoOG from "./assets/MetaOG.png";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>
  // <meta property="og:image" content="https://mwf.io/assets/MetaOG.png" />
  // <meta name="twitter:card" content="https://mwf.io/assets/MetaOG.png" />
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// <SEO
//   title="Home"
//   description="home"
//   content="Metaverse Weapon Factory creates unique NFT weapons with advanced technology."
// ></SEO>

// <Helmet>
//   <title>MWF - METAVERSE WEAPON FACTORY</title>
//   <meta property="og:title" content="MWF - METAVERSE WEAPON FACTORY" />
//   <meta
//     name="description"
//     content="Metaverse Weapon Factory creates unique NFT weapons with advanced technology."
//   />
//   <meta
//     property="og:description"
//     content="Metaverse Weapon Factory creates unique NFT weapons with advanced technology."
//   />
//   <meta name="keywords" content="metaverse, mwf, meta, nft, mining" />
//   <meta property="og:type" content="website" />
//   <meta property="og:url" content="https://mwf.io/" />
// </Helmet>
