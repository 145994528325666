import React, { Fragment, useEffect, useState } from "react";
import "./homepage.styles.css";

const Homepage = () => {
  // const [randomIndex, setRandomIndex] = useState(0);

  const homeDescription =
    "To have the chance to participate in the NFT minting process,";
  const homeDescriptionExtend = "join the whitelist !";

  const catchTitle = [
    {
      title: "Arm Your Metaverse",
      content: "Exclusive NFT Weapons Crafted with Precision",
    },
    {
      title: "Get your NFT",
      content: "weapon ready for the Metaverse",
    },
    {
      title: "Epic Arsenal",
      content: "Unrivaled NFT Weaponry for the Ultimate Metaverse",
    },
    {
      title: "Wield the Future",
      content: "Next-level NFT Weapons for the Ultimate Metaverse Experience",
    },
    {
      title: "Metaverse Weapon Factory",
      content: "Crafting Your Virtual Victory",
    },
    {
      title: "Rule the Metaverse",
      content: "Dominate with Distinctive NFT Weaponry",
    },
    {
      title: "NFT Weaponry Unleashed",
      content: "Master the Metaverse with Style",
    },
    {
      title: "Forge Your Legacy",
      content: "Exceptional NFT Weapons for the Modern Metaverse",
    },
    // {
    //   title: "Metaverse Weapon Factory",
    //   content: "Power,Precision,and Unmatched Presence",
    // },
    {
      title: "Virtual Combat, Elevated",
      content: "NFT Weapons that Redefine the Game",
    },
    { title: "Arm Your Metaverse", content: "NFT Weapons Built for Champions" },
  ];

  const randomIndex = Math.floor(Math.random() * catchTitle.length);

  const [randomTitle, setRandomTitle] = useState(catchTitle[randomIndex]);
  const leftGridDescription = () => (
    <Fragment>
      <div className="hidden md:block mt-4 leading-7 text-[20px] font-Inter pb-4">
        <p>{homeDescription}</p>
        <p className="font-bold">{homeDescriptionExtend}</p>
      </div>
    </Fragment>
  );
  const [windowSize, setWindowSize] = useState([
    window.innerWidth,
    window.innerHeight,
  ]);

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize([window.innerWidth, window.innerHeight]);
    };

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  // useEffect(() => {
  //   setRandomTitle(catchTitle[randomIndex]);
  //   console.log(randomTitle);
  // }, []);

  return (
    <>
      <div
        id="home"
        className="text-center md:text-left w-full bg-mobileBackMwf md:bg-bottom bg-cover md:bg-backgroundMwfNav text-white pb-36 px-0 py-6 md:py-16  md:mx-auto"
      >
        <div className=" mx-auto  grid gap-10 md:gap-0 md:grid-cols-2 md:ml-8 md:mb-16">
          <div className=" md:ml-12 mx-auto my-auto h-fit leading-[280%] md:leading-snug">
            <h1 className=" text-[#00DBCE]  text-[50px] uppercase md:normal-case md:text-[64px] font-bold drop-shadow-3xl ">
              {/* Get your NFT */}
              {randomTitle.title}
            </h1>
            <h1 className="pb-3 text-[38px] md:text-[48px] font-light drop-shadow-3xl">
              {/* weapon ready for <br /> the Metaverse */}
              {randomTitle.content}
            </h1>
            {leftGridDescription()}
            <div className="md:hidden mt-4 leading-7 text-[20px] font-Inter pb-4">
              <p className="">Get your MRA PASS now and get one</p>
              <p className=" 3xl:relative 3xl:right-32">
                {" "}
                of the only 10,000 Genesis
              </p>
              <p className="mx-auto px-8 md:mx-0 md:px-0 col-span-2 max-w-fit font-bold font-Inter">
                It's a complete chaos, protect &nbsp;
                <span className="">Yourself!</span>
              </p>
            </div>
            <a href="#whitelist">
              <button className="max-w-fit md:max-w-full mx-auto md:mx-0 font-Inter text-[18px] font-bold uppercase leading-[132%] text-[#00423E] btn_mint">
                Whitelist
              </button>
            </a>
          </div>
          <div className="  bg-skyBoxWeapon  bg-cover bg-no-repeat bg-center md:bg-auto pr-5  w-[100%] h-[100%]  md:bg-no-repeat mx-auto  ">
            <model-viewer
              style={{
                width: "100%",
                height: parseInt(windowSize) <= 390 ? "40vh" : "60vh",
              }}
              src={process.env.PUBLIC_URL + "/assets/weapon3d-model1.glb"}
              loading="eager"
              exposure="1.5"
              // poster={process.env.PUBLIC_URL + "/assets/weapon3d-poster.png"}
              min-field-of-view="35deg"
              max-field-of-view="35deg"
              camera-controls
              shadow-intensity="4"
              // skybox-image={process.env.PUBLIC_URL + "/assets/background3D.svg"}
              // touch-action="pan-x"
              disable-pan
              alt="A 3D model of an NFT weapon"
            >
              <div id="lazy-load-poster" slot="poster"></div>
            </model-viewer>
          </div>
        </div>
      </div>
    </>
  );
};

export default Homepage;
