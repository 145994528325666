import React, { Fragment } from "react";
import videoAd from "../../assets/pubb.mp4";

const Media = () => {
  return (
    <Fragment>
      {" "}
      <video
        className="md:max-w-[1240px] md:mx-auto  h-full md:my-4  m-0 md:p-4 p-1 w-full object-cover"
        src={videoAd}
        autoPlay
        loop
        muted
      />
    </Fragment>
  );
};

export default Media;

// <div className="md:max-w-[1240px] md:mx-auto  h-full md:my-4  border border-[#00DBCE]/50">
//   <iframe
//     className=" w-full h-full aspect-video"
//     title="background-video"
//     src="https://www.youtube.com/embed/_sapwD7CjaY?controls=0&amp;"
//     allowFullScreen
//     allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
//   />
// </div>
