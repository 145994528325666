import React from "react";

const Button = ({ label }) => {
  return (
    <>
      <div className="rounded-md bg-gradient-to-t from-btnP/100  to-btnP/50 p-[2px]">
        <div className=" rounded-md  bg-[#172e31]">
          <button className="font-Inter transition ease-in-out  max-w-fit p-2 text-[20px] font-[700] leading-[132%] uppercase hover:bg-[#47FFF4]/5  hover:text-[#8CFFF7] duration-300">
            {label}
          </button>
        </div>
      </div>
    </>
  );
};

export default Button;
