import React from "react";
import { ReactComponent as Logo } from "../../assets/logoMwf.svg";
import { Link } from "react-router-dom";
import GoBack from "../../reusableComponents/goBackButton/GoBack.jsx";

const Privacy = () => {
  return (
    <div className=" border md:p-6 p-2 border-gray-400/50 text-white md:text-justify tracking-wide md:md:max-w-[900px] 3xl:max-w-[1240px] h-full mx-auto">
      <div className="w-fit mt-10 my-auto mx-auto">
        <Link to="/">
          <GoBack />
          <Logo className="w-fit mx-auto" />
        </Link>
        <h1 className="w-fit mx-auto py-4 font-bold text-2xl pb-16">
          {" "}
          <Link to="/cgu">Conditions & Terms</Link> |{" "}
          <span className="text-[#00dbce] pointer-events-none">Privacy</span>
        </h1>
      </div>
      <div className=" pointer-events-none w-[90%] mx-auto md:w-full md:mx-0">
        <p className="">
          We, <strong>GBB Corp</strong>, Inc trading as <strong>MWF</strong>{" "}
          located in Delaware, United States (hereinafter: "we", "
          <strong>MWF</strong>"), appreciate your visit to our website and your
          interest in our services. With this privacy policy, we would like to
          explain to you, among other things, which of your personal data is
          processed by us when you visit the website www.mwf.io (hereinafter:
          "www.mwf.io" or "the website") and its respective subpages, as well as
          when you use the functions provided on the website.
        </p>
        <p className="">
          In doing so, we are at the same time fulfilling our duty to inform
          pursuant to Art. 13 of Regulation (EU) 2016/679 of the European
          Parliament and of the Council of 27 April 2016 on the protection of
          individuals with regard to the processing of personal data, on the
          free movement of such data and repealing Directive 95/46/EC (General
          Data Protection Regulation); hereinafter: GDPR.
        </p>
        <h1 className="title-cgu">The Controller</h1>
        <p className="">
          The operator of the digital services and the entity responsible under
          data protection law for the processing of personal data in this
          context is <strong>GBB Corp</strong>, Inc trading as{" "}
          <strong>MWF</strong> located in Delaware, United States
        </p>
        <h1>Personal data</h1>
        <p className="">
          According to Art. 4 No. 1 GDPR, personal data means information
          relating to an identified or identifiable natural person.
        </p>
        <h1 className="title-cgu">
          Legal basis for the processing of personal data
        </h1>
        <div className="">
          <p className="">
            <strong> Consent </strong> - Insofar as we obtain the consent of the
            data subject for processing operations involving personal data, Art.
            6 (1) lit. a GDPR serves as the legal basis.
          </p>
          <p className="">
            <strong>
              Performance of a contract or pre-contractual measures
            </strong>{" "}
            - When processing personal data that is necessary for the
            performance of a contract to which the data subject is a party, Art.
            6 (1) lit. b GDPR serves as the legal basis. This also applies to
            processing operations that are necessary for the performance of
            pre-contractual measures.
          </p>
          <p className="">
            <strong>Legal Obligation </strong>- Insofar as processing of
            personal data is necessary for compliance with a legal obligation to
            which our company is subject, Art. 6 (1) lit. c GDPR serves as the
            legal basis.
          </p>
          <p className="">
            <strong>Vital interests</strong> - In the event that vital interests
            of the data subject or another natural person make processing of
            personal data necessary, Art. 6 (1) lit. d GDPR serves as the legal
            basis.
          </p>
          <p className="">
            <strong>Public Interest</strong> - Where processing is necessary for
            the performance of a task carried out in the public interest or in
            the exercise of official authority vested in the controller, Article
            6 (1) lit. e GDPR shall serve as the legal basis.
          </p>
          <p className="">
            <strong>Legitimate Interest</strong> - If the processing is
            necessary to protect a legitimate interest of our company or a third
            party and the interests, fundamental rights and freedoms of the data
            subject do not outweigh the first-mentioned interest, Article 6 (1)
            lit. f GDPR serves as the legal basis for the processing.
          </p>
        </div>
        <h1 className="title-cgu">General use of the website/log files</h1>
        <p className="">
          When you are on the website and, for example, use our various
          information offers, <strong>MWF</strong> processes information in
          order to be able to provide you with the offers, to ensure a smooth
          connection and a comfortable use of the websites.
        </p>
        <p className="">
          {" "}
          Certain technical data is processed as standard, in particular:
        </p>
        <ul className="list-disc">
          <li>the address of your Internet service provider</li>
          <li>the IP address</li>
          <li>the URL, the name of the website from which you visited us</li>
          <li>the website that you call up from our site</li>
          <li>the date and duration of your visit</li>
          <li>the amount of data transferred as part of this request,</li>
          <li>
            the product and version information about the browser used and
          </li>
          <li>the operating system of your terminal device.</li>
        </ul>
        <p className="">
          We also store the aforementioned data in log files. These log files
          are processed internally and, for example, statistics are collected on
          which website is most frequently visited. This enables us to better
          understand what information you are looking for and want to find on
          our site. We use this data to ensure the operation of our website, in
          particular to detect and eliminate website errors, to determine the
          utilization of the website and to make adjustments or improvements.
          This data is deleted on a regular basis every 30 days.
        </p>
        <p className="">
          {" "}
          In order to offer you our website and to ensure the security of the
          website, to prevent fraud and to correct errors as well as to
          technically provide content on the website, the use of certain cookies
          and similar technologies is necessary. These are listed in our Cookie
          Policy. There you will also receive more detailed information about
          which cookies or technologies are specifically involved.
        </p>
        <p className="">
          {" "}
          All of these named purposes also represent our legitimate interests,
          which we pursue with the processing of personal data associated with
          each of them. Accordingly, the legal basis is Art. 6 para. 1 sentence
          1 lit. f) GDPR).
        </p>

        <h1 className="title-cgu">Registration for and use of www.mwf.io</h1>
        <p className="">
          <strong>MWF</strong> is an advertising token that allows you to
          monetize your community, you can register on the website, in which you
          can Farm your social networks and get paid from Ad Pools.
        </p>
        <p className="">
          {" "}
          For this purpose, we collect further personal data from you, namely
          name, user name, e-mail address and password. In addition, you can
          further expand your user profile and provide us with information about
          you, which we use to personalize our services to you.
        </p>
        <p className="">
          {" "}
          We process the data you enter here to fulfill the user contract
          concluded with you and to provide you with the promised services. The
          legal basis for this is Art. 6 para. 1 lit. b) GDPR. Your data will
          generally be stored for as long as you have a <strong>
            MWF
          </strong>{" "}
          account and deleted after it is terminated.
        </p>
        <h1 className="title-cgu">Contact </h1>
        <p className="">
          If you send us inquiries, your details from the inquiry form,
          including the contact details you have provided there, will be stored
          by us for the purpose of processing the inquiry and in the event of
          follow-up questions. We do not pass on this data without your consent.
        </p>
        <p className="">
          {" "}
          The processing of this data is based on the provision of a
          pre-contractual or contractual measure if your request is related to
          the performance of a contract or is necessary for the implementation
          of pre-contractual measures. In all other cases, the processing is
          based on our legitimate interest in the effective processing of the
          inquiries addressed to us or on your consent if this has been
          requested.
        </p>
        <p className="">
          {" "}
          The data you enter in the contact form will remain with us until you
          request us to delete it, revoke your consent to store it or the
          purpose for storing the data no longer applies (e.g. after we have
          completed processing your inquiry). Mandatory legal provisions - in
          particular retention periods - remain unaffected.
        </p>
        <h1 className="title-cgu">Contractual Relationship</h1>
        <p className="">
          In order to establish or implement the contractual relationship with
          our customers, it is regularly necessary to process the personal
          master, contract, and payment data provided to us. We also process
          customer and prospect data for evaluation and marketing purposes.
        </p>
        <p className="">
          {" "}
          This processing is carried out on the legal basis of our legitimate
          interest and serves our interest in further developing our offer and
          informing you specifically about our offers. Further data processing
          may take place if you have consented or if this serves the fulfillment
          of a legal obligation.
        </p>

        <h1 className="title-cgu">Commercial and business services</h1>
        <p className="">
          We process data of our contractual and business partners, e.g.,
          customers and interested parties in the context of contractual and
          comparable legal relationships as well as related measures and in the
          context of communication with contractual partners (or
          pre-contractual), e.g., to answer inquiries.
        </p>
        <p className="">
          {" "}
          We process this data to fulfill our contractual obligations, to secure
          our rights and for the purposes of the administrative tasks associated
          with this information as well as for business organization. We only
          disclose the data of the contractual partners to third parties within
          the scope of the applicable law to the extent that this is necessary
          for the aforementioned purposes or for the fulfillment of legal
          obligations or with the consent of the contractual partners (e.g., to
          participating telecommunications, transport, and other auxiliary
          services as well as subcontractors, banks, tax and legal advisers,
          payment service providers or tax authorities).
        </p>
        <p className="">
          {" "}
          Unless otherwise specified the purposes of processing are Contractual
          performance and service, contact requests and communication, office
          and organizational procedures, administration, and response to
          requests, visit action evaluation, interest-based and behavioral
          marketing. And, the Legal bases are Contractual performance and
          pre-contractual inquiries, Legal obligation, and our Legitimate
          interests.
        </p>

        <h1 className="title-cgu">
          Use of customer data for direct marketing purposes
        </h1>
        <p className="">
          If you have provided us with your e-mail address, we reserve the right
          to regularly send you e-mail offers for similar goods or services to
          those already purchased from our range. We do not need to obtain your
          separate consent for this. In this respect, the data processing is
          carried out solely on the basis of our legitimate interest in
          personalized direct advertising. If you have initially objected to the
          use of your e-mail address for this purpose, we will not send you any
          e-mails.
        </p>
        <p className="">
          {" "}
          You are entitled to object to the use of your e-mail address for the
          aforementioned advertising purpose at any time with effect for the
          future by notifying the responsible person named at the beginning.
          After receipt of your objection, the use of your e-mail address for
          advertising purposes will cease immediately. If you wish to object to
          the data analysis for statistical evaluation purposes, you must
          unsubscribe from the marketing.
        </p>

        <h1 className="title-cgu">
          Data transfer to payment service providers
        </h1>
        <p className="">
          In order to fulfill the contract, we pass on your data to the company
          commissioned with the payment, insofar as this is necessary for the
          payment of our services. Depending on which payment method you select,
          we pass on the payment data collected for this purpose to the credit
          institution commissioned with the payment and, if applicable, to
          payment service providers commissioned by us or to the selected
          payment service provider. In some cases, the selected payment service
          providers also collect this data themselves. In this case, the privacy
          policy of the respective payment service provider applies.The legal
          basis for the data processing is contract.
        </p>

        <h1 className="title-cgu">
          Data processing for the purpose of fraud prevention and optimization
          of our payment processes
        </h1>
        <p className="">
          Where applicable, we provide our service providers with further data,
          which they use together with the data necessary for the processing of
          the payment as our processors for the purpose of fraud prevention and
          optimization of our payment processes (e.g. invoicing, processing of
          contested payments, accounting support). This serves to protect our
          legitimate interests in our protection against fraud or in efficient
          payment management, which outweigh our interests in the context of a
          balancing of interests.
        </p>

        <h1 className="title-cgu">
          Use of customer data for direct marketing purposes
        </h1>
        <p className="">
          If you have provided us with your e-mail address when purchasing goods
          or services, we reserve the right to regularly send you e-mail offers
          for similar goods or services to those already purchased from our
          range. We do not need to obtain your separate consent for this. In
          this respect, the data processing is carried out solely on the basis
          of our legitimate interest in personalized direct advertising. If you
          have initially objected to the use of your e-mail address for this
          purpose, we will not send you any e-mails.
        </p>
        <p className="">
          {" "}
          You are entitled to object to the use of your e-mail address for the
          aforementioned advertising purpose at any time with effect for the
          future by notifying the responsible person named at the beginning.
          After receipt of your objection, the use of your e-mail address for
          advertising purposes will cease immediately. If you wish to object to
          the data analysis for statistical evaluation purposes, you must
          unsubscribe from the marketing.
        </p>

        <h1 className="title-cgu">
          When you send a data subject access request
        </h1>
        <p className="">
          The legal basis for the processing of your personal data in the
          context of handling your data subject access request is our legal
          obligation and the legal basis for the subsequent documentation of t
          data subject access request is both our legitimate interest and our
          legal obligation. The purpose of processing your personal data in the
          context of processing data when you send a data subject access request
          is to respond to your request. The subsequent documentation of the
          data subject access request serves to fulfill the legally required
          accountability.
        </p>
        <p className="">
          {" "}
          Your personal data will be deleted as soon as they are no longer
          required to achieve the purpose for which they were collected. In the
          case of the processing of a data subject access request, this is three
          years after the end of the respective process.
        </p>
        <p className="">
          {" "}
          You have the possibility at any time to object to the processing of
          your personal data in the context of the processing of a data subject
          access request for the future. In this case, however, we will not be
          able to further process your request. The documentation of the legally
          compliant processing of the respective data subject access request is
          mandatory. Consequently, there is no possibility for you to object.
        </p>

        <h1 className="title-cgu">
          Legal defense and enforcement of our rights
        </h1>
        <p className="">
          The legal basis for the processing of your personal data in the
          context of legal defense and enforcement of our rights is our
          legitimate interest.
        </p>
        <p className="">
          {" "}
          The purpose of processing your personal data in the context of legal
          defense and enforcement of our rights is the defense against
          unjustified claims and the legal enforcement and assertion of claims
          and rights. Your personal data will be deleted as soon as they are no
          longer necessary to achieve the purpose for which they were collected.
        </p>
        <p className="">
          {" "}
          The processing of your personal data in the context of legal defense
          and enforcement is mandatory for legal defense and enforcement of our
          rights. Consequently, there is no possibility for you to object.
        </p>

        <h1 className="title-cgu">
          Disclosure of personal data to third parties and processors
        </h1>
        <p className="">
          As a matter of principle, we do not pass on any data to third parties
          without your consent. If this should nevertheless be the case, then
          the transfer takes place on the basis of the previously mentioned
          legal grounds, e.g. in the case of the transfer of data to payment
          providers for the fulfillment of contracts or due to a court order or
          because of a legal obligation to hand over the data for the purpose of
          criminal prosecution, to avert danger or to enforce intellectual
          property rights. We also use processors (external service providers,
          e.g. for web hosting of our websites and databases) to process your
          data. If data is passed on to the processors as part of a contract
          processing agreement. In doing so, we carefully select our processors,
          regularly monitor them and have been granted a right to issue
          instructions regarding the data. In addition, the processors must have
          taken appropriate technical and organizational measures and comply
          with the data protection regulations according to the GDPR.
        </p>

        <h1 className="title-cgu">Data processing for advertising purposes</h1>
        <p className="">
          In principle, we have a legitimate interest in using your data for
          marketing purposes. We collect the following data for our own
          marketing purposes and for the marketing purposes of third parties:
          first name, last name, postal address.
        </p>
        <p className="">
          {" "}
          The aforementioned data may also be transmitted to third parties
          (advertisers) for this purpose. We are also entitled to store
          additional personal data about you for our own marketing purposes and
          for the marketing purposes of third parties, in compliance with the
          statutory provisions. The aim is to send you advertising that is
          geared solely to your actual or perceived needs and not to bother you
          with useless advertising.
        </p>
        <p className="">
          {" "}
          The stored data will not be passed on to third parties. Furthermore,
          the responsible party pseudonymises / anonymises personal data
          collected about you for the purpose of using the pseudonymised /
          anonymised data for its own marketing purposes as well as for
          marketing purposes of third parties (advertisers).
        </p>
        <p className="">
          {" "}
          The pseudonymised / anonymised data may also be used to advertise to
          you individually online, in which case the advertising may be
          controlled by a service provider / agency. The legal basis for the use
          of personal data for marketing purposes is Article 6(1)(f) GDPR.
        </p>
        <p className="">
          {" "}
          You can object to the use of your personal data for the aforementioned
          marketing purposes at any time free of charge with effect for the
          future by contacting us.
        </p>
        <p className="">
          {" "}
          If you object, your data will be blocked for further data processing
          for advertising purposes. We would like to point out that in
          exceptional cases, advertising material may still be sent temporarily
          after receipt of your objection. This is technically due to the
          necessary lead time within the selection process and does not mean
          that we have not implemented your objection.
        </p>

        <h1 className="title-cgu">Direct marketing</h1>
        <p className="">
          From time to time we may use the personal information we collect from
          you to identify particular products offers which we believe may be of
          interest to you. We may contact you to let you know about these
          products and services and how they may benefit you.
        </p>
        <p className="">
          {" "}
          You may give us your consent in a number of ways including by
          selecting a box on a form where we seek your permission to send you
          marketing information, or sometimes your consent is implied from your
          interactions or relationship with us. Where your consent is implied,
          it is on the basis that you would have a reasonable expectation of
          receiving a marketing communication based on your interactions or
          relationship with us.
        </p>
        <p className="">
          {" "}
          Direct Marketing from generally takes the form of e-mail but may also
          include other less traditional or emerging channels. These forms of
          contact will be managed by <strong>MWF</strong>, or by our contracted
          service providers. Every directly addressed marketing form sent or
          made by us or on our behalf should include a means by which customers
          may unsubscribe (or opt out) of receiving similar marketing in the
          future. You can ask us to remove or amend any previous consent you
          provided by contacting us.
        </p>

        <h1 className="title-cgu">Marketing</h1>
        <p className="">
          We use technologies on our site for the marketing of online
          advertising space, content analysis, market research, personalization
          of your use of www.mwf.io as well as for the integration of external
          content. These technologies, such as cookies, either store data on
          your terminal device or access such data on your terminal device - in
          each case, of course, only with your express consent.
        </p>
        <p className="">
          {" "}
          In order to be able to target a group, our advertising marketer or the
          other service providers must have the necessary information about the
          respective users. Therefore, personal data is processed by us, the
          advertising marketer or the other service providers. However, this is
          not data that allows an actual conclusion to be drawn about your civil
          identity, i.e. in particular your name, address or similar directly
          identifying data, but rather data processed pseudonymously - i.e.
          under a randomly assigned ID. This information is either collected
          directly on www.mwf.io, but also collected from other sites you have
          visited. The marketing of advertising space also involves measuring
          the success and use of the advertisements.
        </p>

        <h1 className="title-cgu">Measurement of ad performance</h1>
        <p className="">
          We use services for the purpose of measuring ad performance. These
          services measure, among other things, whether and how ads were
          displayed to a user and how the user interacted with them, whether an
          ad is displayed in an inappropriate editorial environment
          (brand-unsafe) and determine the percentage of time the ad could have
          been seen, including the duration (ad-perception chance). They also
          create reports on ads, including their effectiveness and performance,
          on user interaction with ads, using data measured in the course of the
          user's interaction with that ad. The legal basis for the data
          processing is your consent pursuant to Art. 6 (1) a) GDPR.
        </p>

        <h1 className="title-cgu">Content measurement</h1>
        <p className="">
          We use services for the purpose of measuring the performance of
          content on our website. This involves measuring and reporting on how
          content is delivered to users and how they have interacted with it.
          The legal basis for data processing is your consent pursuant to Art. 6
          (1) a) GDPR.
        </p>

        <h1 className="title-cgu">
          Market research (insights about target groups)
        </h1>
        <p className="">
          We use services for the purpose of gaining insights into target groups
          for advertising and content. Aggregated reports are generated for
          advertisers or their representatives about the audiences reached by
          their ads, based on survey panels or similar methods.
        </p>
        <p className="">
          {" "}
          Aggregated reports will also be produced for service providers on the
          audiences reached or interacted with by the content and/or ads on
          their services, obtained through survey panels or similar methods. The
          reports do not allow any conclusions to be drawn about a specific or
          identifiable person. However, information about your usage behavior is
          processed in order to create these reports. In addition, offline data
          is assigned to an online user for the purpose of market research in
          order to gain insights into target groups, insofar as providers have
          declared that they will match and merge offline data sources. The
          legal basis for the data processing is your consent pursuant to Art. 6
          para. 1 lit. a) GDPR.
        </p>

        <h1 className="title-cgu">Product development and improvement</h1>
        <p className="">
          We use services for the purpose of developing and improving products,
          e.g. by adding new functions. For this purpose, information about your
          usage behavior is processed. The legal basis for data processing is
          Art. 6 para. 1 lit. a) GDPR.
        </p>

        <h1 className="title-cgu">Third-party integration / social networks</h1>
        <p className="">
          For the purpose of displaying external content and enhancing the user
          experience in our offer, we also embed services of third-party
          providers, each of which is an independent data controller. Subject to
          your consent, your browser establishes a direct connection to the
          server of the respective third-party provider. In this process, the
          third-party provider always processes your IP address, which is
          required to establish the connection to the third-party provider's
          server and to play out the content. The legal basis for the
          integration of these services is your consent pursuant to Art. 6 Para.
          1 lit. a) GDPR.
        </p>

        <h1 className="title-cgu">Recipients of data</h1>
        <p className="">
          Insofar as data is given to special recipients during processing, we
          will inform you separately at the relevant points. In general,
          however, the following applies:
        </p>
        <p className="">
          {" "}
          Internally, your data is generally processed by the institution
          responsible for the processing and its employees responsible for the
          processing purpose.
        </p>
        <p className="">
          {" "}
          We also use external service providers insofar as we cannot or cannot
          reasonably perform services ourselves. These external service
          providers are primarily providers of IT services, such as our hoster,
          e-mail provider or telecommunications provider.
        </p>

        <h1 className="title-cgu">Place of processing</h1>
        <p className="">
          We also process personal data in countries outside the European
          Economic Area ("EEA"), including the USA. To ensure an adequate level
          of data protection, we make use of the EU Commission's standard
          contractual clauses in accordance with Article 46 (2) (c) of the GDPR
          when structuring contractual relationships with the services used in
          third countries, unless an adequacy decision of the European
          Commission exists for the respective third country (standard
          contractual clauses with processors or standard contractual clauses
          with controllers) or an exception exists in accordance with Article 49
          of the GDPR.
        </p>

        <h1 className="title-cgu">Data security</h1>
        <p className="">
          We use extensive technical and organisational security measures to
          protect your personal data from deliberate manipulation, loss or
          access by unauthorised third parties. These are constantly improved
          within the framework of the applicable data protection laws, in
          accordance with technological developments. Nevertheless, we would
          like to point out that despite our security measures, information that
          you voluntarily disclose via the network can be used by others. We
          cannot accept any liability for data transmission errors or
          unauthorised access to your data by third parties.
        </p>

        <h1 className="title-cgu">Social Media</h1>
        <p className="">
          The data you enter on our social media pages, such as comments,
          videos, pictures, likes, public messages, etc. are published by the
          social media platform and are not used or processed by us for any
          other purpose at any time. We only reserve the right to delete content
          if this should be necessary. Where applicable, we share your content
          on our site if this is a function of the social media platform and
          communicate with you via the social media platform. The legal basis is
          our legitimate interest. The data processing is carried out in the
          interest of our public relations and communication.
        </p>
        <p className="">
          {" "}
          If you wish to object to certain data processing over which we have an
          influence, please contact us. We will then examine your objection. If
          you send us a request on the social media platform, we may also refer
          you to other secure communication channels that guarantee
          confidentiality, depending on the response required. You always have
          the option of sending us confidential enquiries to our address stated
          in the imprint.
        </p>
        <p className="">
          {" "}
          As already stated, where the social media platform provider gives us
          the opportunity, we take care to design our social media pages to be
          as data protection compliant as possible. With regard to statistics
          that the provider of the social media platform makes available to us,
          we can only influence these to a limited extent and cannot switch them
          off. However, we make sure that no additional optional statistics are
          made available to us.
        </p>

        <h1 className="title-cgu">
          Data processing by the operator of the social media platform
        </h1>
        <p className="">
          The operator of the social media platform uses web tracking methods.
          The web tracking can also take place regardless of whether you are
          logged in or registered with the social media platform. As already
          explained, we can unfortunately hardly influence the web tracking
          methods of the social media platform. We cannot, for example, switch
          this off. Please be aware: It cannot be ruled out that the provider of
          the social media platform uses your profile and behavioral data, for
          example to evaluate your habits, personal relationships, preferences,
          etc. We have no influence on this. In this respect, we have no
          influence on the processing of your data by the provider of the social
          media platform.
        </p>

        <h1 className="title-cgu">What rights do I have regarding my data?</h1>
        <p className="">
          You have a number of ‘Data Subject Rights’ below is some information
          on what they are and how you can exercise them.
        </p>
        <div className="">
          <p className="">
            <strong>Right to information:</strong> You can request information
            from us as to whether and to what extent we process yourh data.
          </p>
          <p className="">
            <strong>Right to rectification:</strong> If we process your data
            that is incomplete or incorrect, you can request that we correct or
            complete it at any time.
          </p>
          <p className="">
            <strong>Right to erasure:</strong> You may request that we erase
            your data if we are processing it unlawfully or if the processing
            disproportionately interferes with your legitimate interests in
            protection. Please note that there may be reasons that prevent
            immediate deletion, e.g. in the case of legally regulated retention
            obligations. Irrespective of the exercise of your right to deletion,
            we will delete your data immediately and completely, insofar as
            there is no legal or statutory obligation to retain data in this
            respect.
          </p>
          <p className="">
            <strong>Right to restriction of processing:</strong> You may request
            us to restrict the processing of your data if you dispute the
            accuracy of the data for a period of time that allows us to verify
            the accuracy of the data, the processing of the data is unlawful,
            but you object to erasure and request restriction of data use
            instead, we no longer need the data for the intended purpose, but
            you still need this data to assert or defend legal claims, or you
            have objected to the processing of the data.
          </p>
          <p className="">
            <strong>Right to data portability:</strong> You may request that we
            provide you with the data you have provided to us in a structured,
            commonly used and machine-readable format and that you may transfer
            this data to another controller without hindrance from us, provided
            that we process this data on the basis of a revocable consent given
            by you or for the performance of a contract between us, and this
            processing is carried out with the aid of automated procedures. If
            technically feasible, you may request us to transfer your data
            directly to another controller.
          </p>
          <p className="">
            <strong>Right to object: </strong>If we process your data for
            legitimate interest, you may object to this data processing at any
            time; this would also apply to profiling based on these provisions.
            We will then no longer process your data unless we can demonstrate
            compelling legitimate grounds for the processing which override your
            interests, rights and freedoms, or the processing is for the
            assertion, exercise or defense of legal claims. You may object to
            the processing of your data for the purpose of direct marketing at
            any time without giving reasons.
          </p>
          <p className="">
            <strong>Right of complaint:</strong> If you are of the opinion that
            we violate data protection law when processing your data, please
            contact us so that we can clarify any questions. Of course, you also
            have the right to contact the supervisory authority responsible for
            you, the respective state office for data protection supervision. If
            you wish to assert any of the aforementioned rights against us,
            please contact us. In case of doubt, we may request additional
            information to confirm your identity.
          </p>
        </div>
        <p className="">
          Where the processing of your personal information is based on consent,
          you have the right to withdraw that consent without detriment at any
          time.
        </p>
        <p className="">
          {" "}
          The above rights may be limited in some circumstances, for example, if
          fulfilling your request would reveal personal information about
          another person, if you ask us to delete information which we are
          required to have by law, or if we have compelling legitimate interests
          to keep it. We will let you know if that is the case and will then
          only use your information for these purposes. You may also be unable
          to continue using our services if you want us to stop processing your
          personal information.
        </p>
        <p className="">
          {" "}
          We encourage you to get in touch if you have any concerns with how we
          collect or use your personal information.
        </p>

        <h1 className="title-cgu">Children Data</h1>
        <p className="">
          Our website is not intended for children and we do not knowingly
          collect data relating to children. If you become aware that your Child
          has provided us with Personal Data, without parental consent, please
          contact us and we take the necessary steps to remove that information
          from our server.
        </p>

        <h1 className="title-cgu">Hosting</h1>
        <p className="">
          The services for hosting and displaying the website are partly
          provided by our service providers as part of processing on our behalf.
          Unless otherwise explained in this privacy policy, all access data and
          all data collected in forms provided for this purpose on this website
          are processed on their servers. If you have any questions about our
          service providers and the basis of our relationship with them, please
          contact us..
        </p>

        <h1 className="title-cgu">Content Delivery Network</h1>
        <p className="">
          For the purpose of a shorter loading time, we use a so-called Content
          Delivery Network ("CDN") for some offers. With this service, content,
          e.g. large media files, are delivered via regionally distributed
          servers of external CDN service providers. Therefore, access data is
          processed on the servers of the service providers. Our service
          providers work for us within the framework of order processing. If you
          have any questions about our service providers and the basis of our
          cooperation with them, please use the contact option described in this
          privacy policy.
        </p>

        <h1 className="title-cgu">Data Breaches and Notification</h1>
        <p className="">
          Databases or data sets that include Personal Data may be breached
          inadvertently or through wrongful intrusion. Upon becoming aware of a
          data breach, we will notify all affected individuals whose Personal
          Data may have been compromised, and the notice will be accompanied by
          a description of the action being taken to reconcile any damage as a
          result of the data breach. Notices will be provided as expeditiously
          as possible after which the breach was discovered.
        </p>

        <h1 className="title-cgu">Confirmation of Confidentiality</h1>
        <p className="">
          All company employees must maintain the confidentiality of Personal
          Data as well as company proprietary data to which they may have access
          and understand that such Personal Data is to be restricted to only
          those with a business need to know. Employees with ongoing access to
          such data will sign acknowledgement reminders annually attesting to
          their understanding of this requirement.
        </p>

        <h1 className="title-cgu">Accuracy</h1>
        <p className="">
          It is important that the data we hold about you is accurate and
          current, therefore please keep us informed of any changes to your
          personal data.
        </p>

        <h1 className="title-cgu">Do Not Track</h1>
        <p className="">
          Do Not Track is a privacy preference you can set in most browsers. We
          support Do Not Track because we believe that you should have genuine
          control over how your info gets used and our site responds to Do Not
          Track requests.
        </p>

        <h1 className="title-cgu">Do Not Sell My Personal Information </h1>
        <p className="">
          We do not sell information that directly identifies you, like your
          name, address or phone records.
        </p>

        <h1 className="title-cgu">Automated decision-making and profiling</h1>
        <p className="">
          We do not use automation for decision-making and profiling.
        </p>

        <h1 className="title-cgu">Changes </h1>
        <p className="">
          This policy and our commitment to protecting the privacy of your
          personal data can result in changes to this policy. Please regularly
          review this policy to keep up to date with any changes.
        </p>

        <h1 className="title-cgu">Do you still have unanswered questions?</h1>
        <p className="">
          If you have any queries about this Privacy Policy or other questions
          about the scope of personal data, please contact us.
        </p>
      </div>
    </div>
  );
};

export default Privacy;
